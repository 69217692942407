*::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    transition: all ease 0.05s;
    background-color: #cececf;
  }
  
  *::-webkit-scrollbar-thumb {
    background: #556ee6;
    border-radius: 50px;
  }
.avatar.avatar-rounded {
    border-radius: 50%;
}

.avatar {
    position: relative;
    height: 2.625rem;
    width: 2.625rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    color: #fff;
    font-weight: 500;
}
.avatar.avatar-sm {
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: .65rem;
}
.avatar.avatar-rounded img {
    border-radius: 50%;
}
.avatar img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
}
.courses-instructors li {
    -webkit-margin-after: 1rem;
    margin-block-end: 1rem;
}
.category-link {
    padding: 1.25rem;
    border-radius: 0.5rem;
    border: 1px solid #f3f3f3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8c9097;
    transition: all ease .3s;
}
.category-link.primary .category-svg {
    fill: #845adf;
}
.category-link.secondary .category-svg {
    fill: #23b7e5;
}
.category-link.warning .category-svg {
    fill: #f5b849;
}
.category-link.success .category-svg {
    fill: rgb(38,191,148);
}
.category-link .category-svg {
    height: 1.875rem;
    width: 1.875rem;
    -webkit-margin-after: 1rem;
    margin-block-end: 1rem;
    transition: all ease .3s;
}
.category-link.primary.active, .category-link.primary:not(.active):hover {
    color: #845adf;
    background-color: #845adf1a;
    border-color: #845adf33;
}
.category-link.secondary.active, .category-link.secondary:not(.active):hover {
    color: #23b7e5;
    background-color: #23b7e51a;
    border-color: rgba(35,183,229,.2);
}
.category-link.warning.active, .category-link.warning:not(.active):hover {
    color: rgb(245,184,73);
    background-color: rgba(245,184,73,.1);
    border-color: rgba(245,184,73,.2);
}
.category-link.success.active, .category-link.success:not(.active):hover {
    color: rgb(38,191,148);
    background-color: rgba(38,191,148,.1);
    border-color: rgba(38,191,148,.2);
}
.kanban-board {
    display: flex;
    overflow-x: auto;
    align-items: stretch;
    margin-bottom: 0.75rem;
    padding: 1.5rem;
    background-color: #f0f1f7;
    border-radius: 0.5rem;
    border: 2px solid #556ee6;
    border-bottom: none;
}
.kanban-board .kanban-tasks-type {
    min-width: 20rem;
    -webkit-margin-end:1rem;
    margin-inline-end: 1rem;
}
.cursor-pointer{
    cursor: pointer;
}

         .select2-container {
                 width: 100% !important;
             }
                     /* Styling for the custom file input container */
                     .custom-file-input {
                 position: relative;
                 display: inline-block;
                 cursor: pointer;
                 border: 1px solid #ccc;
                 padding: 0.47rem 1.75rem 0.47rem 0.75rem;
                 border-radius: 5px;
                width: 90%;
             }
    
             /* Styling for the actual file input */
             .custom-file-input input[type="file"] {
                 display: none;
             }
             .avatar-md{
     height: 2rem;
     width: 2rem;
             }

.editor-input{
    height: 300px;
}

.no-data-found{
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.verification-btn{
    font-family: \'Helvetica Neue\',Helvetica,Arial,sans-serif;
     box-sizing: border-box;
      font-size: 14px; color: #fff;
       text-decoration: none;
        line-height: 2em;
         font-weight: bold;
          text-align: center;
           cursor: pointer;
            display: inline-block;
             border-radius: 5px;
              text-transform: capitalize; 
              background-color: #34c38f; 
              margin: 0;
               border-color: #34c38f;
                border-style: solid;
                 border-width: 8px 16px;
}

.card-title {
    font-size: 0.8rem !important;
}

.btn {
    font-size: 0.7rem;
}

.card select,
.card input {
    height: 25px !important;
    padding: 0 1.75rem 0 0.75rem !important;
    font-size: 0.7rem !important;
}

.btn-light {
    height: 25px;
    padding: 0 0.75rem;
    font-size: 0.7rem !important;
}

.myDiv {
    display: flex;
}

.select2-container {
    width: 100% !important;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.btn-xs:focus {
    box-shadow: none !important;
}

.table-wrapper {
    max-height: 200px;
    overflow-y: auto;
}

.category-container {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 1rem;
}

table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    font-size: 0.7rem;
}

th,
td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

thead {
    position: sticky;
    top: 0;
    background-color: white;
}

.btn-sm {
    font-size: 0.5rem;
}

.extra-row {
    display: none;
}

.topnav {
    background-color: #111c43 !important;
    margin: 0 !important;
}

.topnav .navbar-nav .nav-link {
    color: #fff !important;
}
.input-container {
position: relative;
}

.input-container input {
width: 100%;
padding-right: 50px; /* Make space for icons */
}

.input-icons {
position: absolute;
top: 70%;
right: 30px;
transform: translateY(-50%);
}

.input-icons i {
cursor: pointer;
margin-left: 10px;
}

.px-11{
    padding: 0 110px;
}


.select2-container {
    width: 100% !important;
}

.dropdown {
    position: relative;
    font-size: 14px;
    color: #182433;
}

.dropdown .dropdown-list {
    padding: 0;
    background: #ffffff;
    position: absolute;
    top: 30px;
    left: 2px;
    right: 2px;
    z-index: 1000;
    border: 1px solid rgba(4, 32, 69, 0.14);
    border-radius: 4px;
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.07), 0px 6px 30px 5px rgba(0, 0, 0, 0.06), 0px 8px 10px -5px rgba(0, 0, 0, 0.1);
    transform-origin: 50% 0;
    transform: scale(1, 0);
    transition: transform 0.15s ease-in-out 0.15s;
    max-height: 66vh;
    overflow-y: scroll;
}

.dropdown .dropdown-option {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
}

.dropdown .dropdown-label {
    display: block;
    background: #fff;
    font-family: var(--tblr-font-sans-serif);
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.4285714286 !important;
    padding: 0.4375rem 0.75rem !important;

    cursor: pointer;
    border: 1px solid #dadfe5;
    border-radius: 4px;
}

.dropdown .dropdown-label:before {
    font-family: "Material Design Icons";
    content: "\f0140";
    color: #a5a9b1;
    float: right;
}

.dropdown.on .dropdown-list {
    transform: scale(1, 1);
    transition-delay: 0s;
}

.dropdown.on .dropdown-list .dropdown-option {
    opacity: 1;
    transition-delay: 0.2s;
    color: #182433;
}

.dropdown.on .dropdown-label:before {
    content: "\f0143";
    font-family: "Material Design Icons";
    color: #a5a9b1;
}

.dropdown [type="checkbox"] {
    position: relative;
    top: -1px;
    margin-right: 4px;
}

.kanban-board .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-list {
    height: 330px;
    overflow-y: auto;
    padding: 0.5rem;

}

.card select,
.card input {
    height: 25px !important;
    padding: 0 1.75rem 0 0.75rem !important;
    font-size: 0.7rem !important;
}

.btn-light {
    height: 25px;
    padding: 0 0.75rem;
    font-size: 0.7rem !important;
}

.card-title {
    font-size: 0.8rem !important;
}

.img-20 {
    width: 20px !important;
}

.select2-container {
    width: 100% !important;
}

.custom-file-input {
    position: relative;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 0.47rem 1.75rem 0.47rem 0.75rem;
    border-radius: 5px;
    width: 90%;
}

/* Styling for the actual file input */
.custom-file-input input[type="file"] {
    display: none;
}

.avatar-md {
    height: 2rem;
    width: 2rem;
}

.myDiv {
    display: none;
}

.task-list .task-box:last-child {
    margin: 0 !important;
}
/* 
.rbc-row{
    display: flex;
    margin:1px;
    border: 1px solid rgb(73, 73, 73);
}


.rbc-button-link {
    width: 103px;
    height: 50px;
}

.rbc-header {

} */



.rbc-month-row{
    height: 10px 0;
    display: flex;
}

.prev-calendar-btn{
    padding: 5px 10px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.next-calendar-btn{
    padding: 5px 10px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.today-calendar-btn{
    padding: 5px 10px;
    border-radius: 3px;
}

.view-calendar-btn{}

.calendar-btn-font{
    font-size: 15px;
    /* font-weight: bolder; */
}

.rbc-header{
    color: rgb(69, 69, 246);
    font-weight: bolder;
}

.dropdown-button {
    background-color: #f0f0f0; 
    border: 1px solid #ccc; 
    color: #333; 
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
  }
  .dropdown-button::after {
    content: '\2026'; 
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }